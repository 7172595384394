<template>
  <div>
    <b-card
      :title="$t('change_password')"
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-form
        @submit.stop.prevent="changePasswordSubmit"
      >
        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            class="card-filter-item"
          >
            <b-input-group class="mb-1">
              <b-input-group-prepend is-text>
                <span
                  style="min-width: 130px;"
                  v-text="$t('new_password')"
                />
              </b-input-group-prepend>
              <b-form-input
                id="new-password"
                v-model="formData.new_password"
                type="password"
                autocomplete="off"
              />
            </b-input-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            class="card-filter-item"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <span
                  style="min-width: 130px;"
                  v-text="$t('confirm_the_new_password')"
                />
              </b-input-group-prepend>
              <b-form-input
                id="new-password-confirm"
                v-model="formData.new_password_confirmation"
                type="password"
                autocomplete="off"
              />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="12"
            class="card-filter-item mt-1"
          >
            <b-button
              variant="primary float-right"
              @click="changePasswordSubmit"
              v-text="$t('update')"
            />
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'

import {
  BForm,
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Account from '@/models/Account'

import { EventBus } from '../../../eventbus'

export default {
  name: 'SettingChangePassword',
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 50,
      loading: true,
      formData: {
        new_password: '',
        new_password_confirmation: '',
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      EventBus.$emit('getMyUpdatedUserData')
    },
    resetFormData() {
      this.formData = {
        new_password: '',
        new_password_confirmation: '',
      }
    },
    async changePasswordSubmit() {
      try {
        const response = await Account.changeMyPassword(this.formData)
        if (response.data.status) {
          this.$swal({
            title: 'Success!',
            text: response.data.results.message,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.resetFormData()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.results.message,
            },
          }, {
            position: 'top-center',
          })
          if (typeof response.data.results.field_id !== 'undefined') {
            document.getElementById(response.data.results.field_id).focus()
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
  },
}
</script>

<style>
</style>
